import { KenticoChoice } from '@utils/KontentTypes';

interface SEOProps {
  seoPageTitle: { value: string };
  seoPageDescription: { value: string };
  seoNoindex: { value: KenticoChoice[] };
  seoNofollow: { value: KenticoChoice[] };
  url: { value: string };
}

export const mapToSEO = (data: SEOProps) => {
  const { seoPageTitle, seoPageDescription, seoNoindex, seoNofollow, url } =
    data;
  return {
    title: seoPageTitle.value,
    description: seoPageDescription.value,
    noindex: seoNoindex.value[0].codename,
    nofollow: seoNofollow.value[0].codename,
    url: url.value.startsWith('/') ? url.value : '/' + url.value,
  };
};
